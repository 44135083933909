.keyvisual {
    position: relative;
    overflow: hidden;
    height: auto;
    padding: 0 pxToRem(64) pxToRem(64);
    margin-top: $header-height;

    .image {
        position: unset; //reset
    }

    .image img {
        position: absolute; //reset
        height: 100%;
    }

    .keyvisual__slider {
        position: relative;
    }
}

.keyvisual--default {
    max-height: 100vh;

    .keyvisual__slider, .keyvisual__wrapper, .keyvisual__wrapper > ul, .keyvisual__slide, .image__outer, .image {
        height: 100%;
        max-height: calc(100vh - #{$header-height} - #{pxToRem(64)});
    }
}

.keyvisual--big {
    height: calc(100vh - $header-height);

    .keyvisual__slider, .keyvisual__wrapper, .keyvisual__wrapper > ul, .keyvisual__slide, .image__outer, .image {
        height: 100%;
    }
}

.keyvisual-empty {
    height: $header-height;
}

//@include media-breakpoint-down(xl) {
@media(max-width: 1340px) {
    .keyvisual--big {
        // dieser Fall tritt vermutlich nie auf (siehe unten)
        height: calc(100vh - $header-height-mobile);
    }
    .keyvisual {
        margin-top: $header-height-mobile;
    }
}


@supports (height: 100svh) {
    @include media-breakpoint-down(xl) {
        .keyvisual--big {
            height: calc(100vh - #{$header-height-mobile});
        }
    }
}

@include media-breakpoint-down(lg) {
    .keyvisual {
        padding: 0 pxToRem(24) pxToRem(24);
    }
}

@include media-breakpoint-down(md) {
    .keyvisual--big {
        height: auto;
    }

    .keyvisual-empty {
        height: $header-height-mobile;
    }
}

/*** || Claim ***/

.claim {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    color: $color-white;
    text-align: center;
    pointer-events: none;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.16);
    }

    &__content {
        z-index: 10;
        width: auto;
        height: auto;
        @include getFigmaStyle('Desktop / Xxl / Bold / Default');
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
    }
}

@include media-breakpoint-down(md) {
    .claim {
        text-align: center;
    }
}

/*** || Booking-Button ***/

.keyvisual__button {
    position: absolute;
    bottom: calc(#{pxToRem(64)} + #{pxToRem(48)});
    z-index: 99;
    display: flex;
    justify-content: center;
    width: calc(100% - #{pxToRem(128)});
}

@include media-breakpoint-down(lg) {
    .keyvisual__button {
        bottom: calc(#{pxToRem(24)} + #{pxToRem(28)});
        width: calc(100% - #{pxToRem(48)});

        .button {
            padding: pxToRem(10) pxToRem(36);
        }
    }
}