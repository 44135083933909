* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: $base-font-weight;
    color: $font-color;
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none;
}

ins {
    text-decoration: none;
}

h1, .h1, h2, .h2 {
    @include getFigmaStyle('Desktop/H1/Bold/Default');
    margin-bottom: pxToRem(48);
}

.modulspacing {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

.bgcolorspacing {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }
}

.bgcolor {
    background: $color-soft-yellow;
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }

    .modulspacing:first-child {
        margin-top: initial;
    }

    .modulspacing:last-child {
        margin-bottom: initial;
    }
}

.text-center {
    text-align: center;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }

    h1, .h1, h2, .h2 {
        margin-bottom: pxToRem(28);
    }
}