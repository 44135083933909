@import "@sx-core/src/scss/base/video.base.scss";

.video {
    background: #eee; //$color-grey-100;
}

.keyvisual .video__unmute {
    bottom: calc(#{pxToRem(64)} + #{pxToRem(48)} + #{pxToRem(16)});
    background-color: rgba($color-white, 0.6);
}

.keyvisual .video__unmute.active {
    background-color: rgba($color-white, 0.9);
}

@include media-breakpoint-down(lg) {
    .keyvisual .video__unmute {
        bottom: pxToRem(96);
    }
}