
.formular-donut {
    display: none;
}

.formular .grid > [class*=col], .formular .grid > [class^=col] {
    margin-bottom: pxToRem(28);
}

.formular.formular--horizontal .formular-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.formular.formular--horizontal .formular-item > * {
    flex: 1 1 auto;
}

.formular.formular--horizontal .formular-item__label {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 4px;
}

@include media('sm') {
    .formular.formular--horizontal .formular-item__label {
        width: 160px;
    }
}

.formular.formular--vertical .formular-item__label {
    margin-bottom: 12px;
}


.formular.formular--horizontal .fomular-item__error {
    flex: 0 0 auto;
    width: 100%;
}

.formular-item {
    margin-bottom: 0;
}

.formular-item__label > span:not(:last-child),
.custom-control-label > span:not(:last-child),
{
    margin-right: 4px;
}

.formular-item__checkbox .formular-item__required {
    margin-left: 4px;
}

.formular-item__body {
    display: flex;
    flex-direction: column;
}

.formular .formular-item__label {
    display: flex;
    align-items: center;
}

.formular-item__required, .form-error label, {
    color: $color-danger;
}


.formular-item__tooltip {
    font-size: $base-font-size*0.8;
    color: $color-info;
    width: $base-font-size * 0.8;
    height: $base-font-size * 0.8;
    display: inline-block;
}

.formular-item__tooltip:before {
    content: '\00a0';
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: inline-svg('question', $color-primary-dark);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.formular-item__infotext {
    margin-top: 5px;
    font-size: $base-font-size*0.8;
    color: $color-grey-700;
}

.fomular-item__error > .error-message {
    margin-top: 3px;
    display: block;
    color: $color-danger;
    font-size: $base-font-size*0.8;
}

.formular-item__checkbox--single > .checkbox > label,
.formular-item__radio--single > .radio > label {
    min-height: 34px;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
}

/*****/

.formular {
    padding: pxToRem(128) 0 pxToRem(96);
    background-color: $color-soft-yellow;

    .formular-item__label {
        @include getFigmaStyle('Desktop/SM/Bold/Default');
    }

    span > a {
        text-decoration: underline;
    }
}

.formular__requiredtext p {
    @include getFigmaStyle('Desktop/XS/Bold/Default');
}

