.faqteaser__outer {
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);
    background-color: $color-soft-yellow;
}

.item-faq__storage {
    display: none;
}

.item-faq__content {
    display: none;
}

.faqteaser__outer {
    .item-faq__content-outer {
        padding: pxToRem(36) pxToRem(20);
        color: $color-white;
        background: $color-primary-800;
        @include getFigmaStyle('Shadow/LG');
    }
}

.faqteaser-item {
    margin-bottom: pxToRem(48);
}

.faqteaser__item {
    position: relative;
    height: 100%;
    padding: pxToRem(16) pxToRem(28);
    @include getFigmaStyle('Shadow/SM');
    background-color: $color-white;
    transition: all .3s ease;

    //&:hover, &:focus, &.show-arrow {
    //    @include getFigmaStyle('ShadowLg');
    //}

    @include media-breakpoint-up(md) {
        &:hover, &:focus, &.show-arrow {
            //box-shadow: 0 4px 36px 2px rgb(0 0 0 / 20%);
        }

        &:hover, &:focus {
            cursor: pointer;
        }
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -48px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px;
        border-color: transparent transparent $color-primary-800;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .3s ease;
    }

    &.show-arrow::after {
        opacity: 1;
    }

    &.show-arrow .faqteaser__body::after {
        transform: rotate(-45deg);
    }
}

.faqteaser__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 56px);
    padding: pxToRem(12) 0;

    .faqteaser-content__title--headline {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    .faqteaser-content__title--subline {
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    &::after {
        content: '';
        position: absolute;
        top: pxToRem(24);
        right: pxToRem(24);
        display: block;
        width: 36px;
        height: 36px;
        background-color: $color-primary-800;
        background-image: inline-svg('plus-md', $color-white, transparent);
        background-position: center;
        background-size: 24px;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all .3s ease;
    }
}

.faqteaser__body-headline {
    display: inline-block;
}

.faqteaser__productimg {
    width: 100%;
    max-width: 150px;
    height: 100%;
    margin-left: pxToRem(8);

    &--mariagalland {
        width: 148px;
    }

    &--thalgo {
        width: 90px;
    }

    &--pino {
        width: 86px;
    }

    &--ellabache {
        width: 116px;
    }

    &--sothys {
        width: 112px;
    }
}

.item-faq__content_inner {
    color: $color-white;

    p {
        color: $color-white;
    }
}

.faqteaser__price-outer {
    margin-top: pxToRem(32);

    .faqteaser__price {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }
}

.item-faq__content {
    margin-bottom: pxToRem(48);
}

.accordionteaser__addition {
    margin-top: pxToRem(2);
}

.accordionteaser__notice {
    padding-top: pxToRem(16);
    margin-top: pxToRem(16);
    border-top: 1px solid $color-primary-1300;
}

.faqteaser__button {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(36);
}

.faqteaser__more-faqteaser {
    display: none;
}

@include media-breakpoint-down(md) {
    .faqteaser-item, .item-faq__content {
        margin-bottom: pxToRem(24);
    }

    .faqteaser__item {
        padding: pxToRem(24) pxToRem(20) pxToRem(36);

        &::after {
            content: none;
        }
    }

    .faqteaser__body {
        flex-direction: column;
        align-items: flex-start;
        //width: calc(100% - 36px);
        width: 100%;
        padding: initial;

        &::after {
            //top: pxToRem(24);
            //right: pxToRem(20);
            top: initial;
            bottom: 0;
            left: 50%;
            right: initial;
            transform: translate(-50%, 50%) rotate(0);
            width: pxToRem(28);
            height: pxToRem(28);
            background-size: pxToRem(20);
        }

        .faqteaser-content__title--subline {
            margin-top: pxToRem(4);
        }
    }

    .faqteaser__item.show-arrow .faqteaser__body::after {
        transform: translate(-50%, 50%) rotate(-45deg);
    }

    .faqteaser__body-img {
        width: 100%;
        //padding-top: pxToRem(16);
        margin-top: pxToRem(22);
        //border-top: 1px solid $color-soft-yellow;

        .faqteaser__productimg {
            margin: auto;
        }
    }

    .faqteaser__outer .item-faq__content-outer {
        padding: pxToRem(24) pxToRem(20);
    }
}

/*** || Accordion-Imageteaser ***/

.item-accordion-imageteaser__storage {
    display: none;
}

.item-accordion-imageteaser__content {
    display: none;
}

.accordion-imageteaser__outer {
    .item-accordion-imageteaser__content-outer {
        padding: pxToRem(48);
        color: $color-white;
        background: $color-primary-800;
    }
}

.accordion-imageteaser-item {
    margin-bottom: pxToRem(48);
}

.accordion-imageteaser__item {
    position: relative;
    @include getFigmaStyle('Shadow/SM');
    background-color: $color-white;
    transition: all .3s ease;

    //&:hover, &:focus, &.show-arrow {
    //    @include getFigmaStyle('ShadowLg');
    //}

    @include media-breakpoint-up(md) {
        &:hover, &:focus, &.show-arrow {
            box-shadow: 0 4px 36px 2px rgb(0 0 0 / 20%);
        }

        &:hover, &:focus {
            cursor: pointer;
        }
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -48px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px;
        border-color: transparent transparent $color-primary-800;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .3s ease;
    }

    &.show-arrow::after {
        opacity: 1;
    }
}

.accordion-imageteaser__body {
    width: 100%;
    padding: pxToRem(28) pxToRem(20);

    .accordion-imageteaser-content__title--headline {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    .accordion-imageteaser-content__title--subline {
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }
}

.item-accordion-imageteaser__content_inner {
    color: $color-white;

    p {
        color: $color-white;
    }

    .content a {
        color: $color-white;
        text-decoration: underline;
    }
}

.accordion-imageteaser__price-outer {
    margin-top: pxToRem(32);

    .accordion-imageteaser__price {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }
}

.item-accordion-imageteaser__content {
    margin-bottom: pxToRem(48);

    .content {
        color: $color-white;

        h1, h2, h3, h4, h5, h6 {
            color: $color-white;
        }
    }
}

.accordionteaser__addition {
    margin-top: pxToRem(2);
}

.accordionteaser__notice {
    padding-top: pxToRem(16);
    margin-top: pxToRem(16);
    border-top: 1px solid $color-primary-1300;
}

.accordion-imageteaser__content-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: pxToRem(48);
    margin-top: pxToRem(36);
}

@include media-breakpoint-down(md) {
    .accordion-imageteaser-item, .item-accordion-imageteaser__content {
        margin-bottom: pxToRem(24);
    }

    .accordion-imageteaser__item::after {
        bottom: -24px;
    }

    .accordion-imageteaser__body {
        padding: pxToRem(20);
    }

    .accordion-imageteaser__outer .item-accordion-imageteaser__content-outer {
        padding: pxToRem(36) pxToRem(16);
    }

    .accordion-imageteaser__content-images {
        grid-template-columns: 1fr;
        gap: pxToRem(16);
    }
}