.headline.headline--topline {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
}

.cta {
    display: flex;
    flex-wrap: wrap;
    column-gap: pxToRem(28);
    row-gap: pxToRem(12);
    margin-top: pxToRem(48);
}

.keyvisual + .article--intro {
    margin-top: calc(#{pxToRem(128)} - #{pxToRem(64)});
}

.article {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        display: block;
        //bottom: 20%;
        bottom: 50%;
        transform: translateY(50%);
        width: 70vw;
        height: auto;
        aspect-ratio: 1.6 / 1;
        background-image: inline-svg('initial');
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: -1;
    }

    &--even::after {
        left: -20px;
    }

    &--odd::after {
        right: 0;
    }
}

.article.content--empty {
    margin-bottom: pxToRem(64);

    & + .modulspacing {
        margin-top: pxToRem(64);
    }

    &::after {
        content: none;
    }
}

@include media-breakpoint-down(lg) {
    .article {
        &::after {
            content: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .article.content--empty {
        margin-bottom: pxToRem(36);

        & + .modulspacing {
            margin-top: pxToRem(36);
        }
    }

    .cta {
        margin-top: pxToRem(28);
    }
}