
.breadcrumb {
}

.breadcrumb__item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.breadcrumb__item:not(:first-child):before {
    content: '';
    display: inline-block;
    width: $base-font-size;
    height: $base-font-size;
    margin: 0 10px;
    background-size: $base-font-size $base-font-size;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-right', $color-grey-100);
}

