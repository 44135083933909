.header {
    position: absolute;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-height;
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(36);
    background: $color-white;
}

body:not(.js-nav-scrolled02) .header:has(+ .keyvisual-empty) {
    border-bottom: 1px solid $color-soft-yellow;
}

.header > .container,
.header > .container > .grid {
    height: 100%;
}

.header__logo {
    display: block;

    &--default {
        height: 96px;
        width: auto;
    }
}

.header__logo--desktop {
    display: none;
}

.header__logo--scrolled {
    display: none;
}

.header__logo--desktop-scrolled {
    img {
        height: 64px;
        width: auto;
    }
}

//@include media-breakpoint-up(xl) {
@media(min-width: 1340px) {

    .header__logo {
        margin-left: auto;

        .header__logo--scrolled {
            display: none;
        }
    }

    .js-nav-scrolled .header {
        height: 106px;
        padding: initial;
        box-shadow: 0 4px 36px rgba(0, 0, 0, 0.200);

        .header__logo {
            &--default {
                display: none;
            }

            &--desktop {
                display: flex;
                align-items: center;
                height: 100%;
                margin-top: initial;
                margin-right: pxToRem(64);
            }
        }

        .header__navigation-topline {
            display: flex;
            align-items: center;
            height: 100%;
        }

        .header__navigation {
            flex-direction: row-reverse;
            align-items: flex-end;
            width: 100%;
        }

        .header__navigation > ul {
            padding-bottom: pxToRem(16);
        }

        .header-cta--booking.button--sm {
            padding: pxToRem(10) pxToRem(34);
        }

        .header-cta--contact, .header-cta--phone {
            display: none;
        }
    }
}

.js-nav-scrolled .header {
    transform: translateY(-100%);
}

.js-nav-scrolled02 .header {
    position: fixed;
    transform: translateY(0);
    transition: all 1s ease;
}

@include media-breakpoint-down(xxl) {
    .js-nav-scrolled {
        .header .header__logo {
            &--desktop {
                margin-right: pxToRem(16);
            }
        }
    }
}

//@include media-breakpoint-down(xl) {
@media(max-width: 1340px) {
    .header {
        height: $header-height-mobile;
        padding-top: initial;
        padding-bottom: initial;
        //transition: all 1s ease;

        .grid {
            justify-content: space-between;
            align-items: center;
        }
    }

    .header__logo {
        &--default {
            height: 72px;
        }

        &--scrolled {
            height: 48px;
        }
    }

    //.js-nav-scrolled02 {
    //    .header {
    //        trans
    //    }
    //}
}

