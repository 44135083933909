.factbox {
    &__inner {
        background-color: $color-soft-yellow;
    }

    &__content {
        padding: pxToRem(48);
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: pxToRem(36);
        padding: pxToRem(28) pxToRem(48);
        background-color: $color-soft-yellow;
        border-top: 1px solid $color-white;
    }

    &__price {
        @include getFigmaStyle('Desktop/H3/Bold/Default');

        &-addition {
            margin-top: pxToRem(2);
            @include getFigmaStyle('Desktop/MD/Regular/Default');
        }
    }

    &__button {
        margin-left: auto;
    }
}

.factbox-content {
    &__headline {
        margin-bottom: pxToRem(36);
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }

    &__facts {
        display: flex;
        flex-wrap: wrap;
        column-gap: pxToRem(16);
        row-gap: pxToRem(16);
        margin-bottom: 0 !important;

        li.fact {
            flex-basis: calc(50% - 8px);
            @include getFigmaStyle('Desktop/MD/Regular/Default');
        }
    }
}

@include media-breakpoint-down(md) {
    .factbox {
        &__content {
            padding: pxToRem(48) pxToRem(28);
        }

        &__footer {
            padding: pxToRem(28);
        }
    }

    .factbox-content {
        &__facts {
            li.fact {
                flex-basis: 100%;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .factbox {
        &__button {
            flex-basis: 100%;
        }
    }
}