.footer {
    font-size: pxToRem(17);
    background-color: $color-soft-yellow;

    .footer-grid {
        flex-direction: column;
    }

    .footer__navigation {
        ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: pxToRem(36);
            row-gap: pxToRem(12);
        }

        li > a {
            color: $color-secondary-1300;
            @include getFigmaStyle('Desktop/MD/Regular/Default');
        }
    }
}

.footer-top {
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);
}

.footer__rating {
    margin-left: auto;

    img {
        width: 140px;
        height: auto;
    }
}

.formular:has( + .footer) {
    margin-bottom: 0;
}

.teaser-usp, .asp, .formular {
    & + .footer {
        border-top: 1px solid $color-white;
    }
}

@include media-breakpoint-down(lg) {
    .footer {
        padding: pxToRem(48) 0;
    }

    .footer-top {
        order: 1;
        padding: initial;
    }

    .footer-bottom {
        order: 2;
    }

    .footer-partner {
        order: 3;
    }

    .footer__rating {
        padding: pxToRem(28) 0;
        margin: auto;
    }
}

/*** || Adresse ***/

.footer__address {
    .address__title {
        margin-bottom: pxToRem(20);
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    .address__phone {
        margin-top: pxToRem(12);
    }

    .address__item a {
        color: $color-secondary-1300;
    }

    .address__item span {
        display: inline-block;
        margin-right: pxToRem(12);
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }
}

/*** || Newsletter ***/

.footer__newsletter {
    &--headline {
        margin-bottom: pxToRem(20);
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    &--content {
        margin-bottom: pxToRem(36);
    }

    .footer-newsletter__input {
        height: pxToRem(48);
        padding: 0 pxToRem(48) 0 pxToRem(26);
        color: $color-secondary-1300;
        border: none;
        border-radius: 320px !important;

        &:hover, &:focus {
            border: none;
        }
    }

    .input, .input:hover, .input:focus {
        box-shadow: initial;
    }

    .formular-item__body {
        box-shadow: 0 0 3px rgba($color-black, 0.16);
        border-radius: 320px;
        transition: all 0.3s ease;

        &:hover, &:focus {
            box-shadow: 0 0 0 3px $color-primary-400;
        }
    }

    .input-group {
        align-items: center;
        background-color: $color-white !important;
        border-radius: 320px;

        .button.btn--footer-newsletter {
            position: absolute;
            right: pxToRem(6);
            width: pxToRem(36);
            height: pxToRem(36);
            background-image: inline-svg('arrow-right-md', $color-white);
            background-position: center;
            background-size: pxToRem(16);
            background-repeat: no-repeat;
            border: none;
            border-radius: 50%;
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer__newsletter {
        padding-bottom: pxToRem(28);
        margin-top: pxToRem(28);
        border-bottom: 1px solid $color-white;
    }
}

/*** || Partner ***/

.footer-partner {
    display: flex;
    align-items: center;
    padding: pxToRem(48) 0;
    border-top: 1px solid $color-white;
    border-bottom: 1px solid $color-white;

    .partner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        row-gap: pxToRem(48);
    }

    .partner__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: calc(100% / 9);
        padding: 0 pxToRem(28);

        img {
            max-height: 100px;
            width: 100%;
        }
    }
}

@include media-breakpoint-down(xxl) {
    .footer-partner {
        .partner__logo {
            max-width: calc(100% / 7);
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer-partner {
        .partner__logo {
            max-width: initial;
            flex-basis: calc(100% / 4);
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer-partner {
        .partner__logo {
            flex-basis: 75%;
            max-width: 170px;
        }
    }
}

/*** || Socials ***/

.socials {
    position: relative;
    display: flex;
    column-gap: pxToRem(12);
    padding-right: pxToRem(28);
    margin-right: pxToRem(28);

    img {
        width: 40px;
        height: auto;
    }
}

/*** || Footer Bottom ***/

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(64) 0;

    .footer__cookies {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: pxToRem(32);
        padding-left: pxToRem(48);
        color: $color-secondary-1300;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all .3s ease;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            width: 36px;
            height: 36px;
            background-image: inline-svg('cookie', $color-secondary-1300, transparent);
            background-position: center;
            background-size: 30px;
            background-repeat: no-repeat;
            border: 1px solid $color-secondary-1300;
            border-radius: 50%;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus, &:active {
                text-decoration-color: $color-secondary-1300;
            }
        }

        &.footer__cookies--mobile {
            color: $color-secondary-1300;
            text-decoration: none;
        }
    }
}

address, .footer__address {
    font-style: normal;
}

@include media-breakpoint-down(lg) {
    .footer-bottom {
        padding: initial;

        .footer__navigation {
            width: 100%;
            order: 1;
            padding: pxToRem(28) 0;
            border-top: 1px solid $color-white;
        }

        .footer__socials {
            order: 2;
            width: 100%;
            padding: pxToRem(28) 0;
            margin-right: initial;
            border-top: 1px solid $color-white;
        }

        .footer__cookies {
            order: -1;
            margin-left: initial;
            margin-right: auto;
        }
    }
}