@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: $color-secondary-1300;
    }

    h1, .h1 {
        @include getFigmaStyle('Desktop/H1/Bold/Default');
    }

    h2, .h2 {
        @include getFigmaStyle('Desktop/H2/Bold/Default');
    }

    h3, .h3 {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }

    h4, .h4 {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    h5, .h5 {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    h6, .h6 {
        @include getFigmaStyle('Desktop/H6/Bold/Default');
    }

    &.content {
        h2, h3, h4, h5, h6 {
            margin-top: pxToRem(48);
            margin-bottom: pxToRem(16);
        }
    }

    p {
        color: $color-secondary-1300;
    }

    p:not(:last-child) {
        margin-bottom: pxToRem(16);
    }

    .content a, &.content a {
        color: $color-secondary-1300;
        text-decoration: underline;
    }

    ul, ol {
        margin: pxToRem(28) 0;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        margin-bottom: pxToRem(20);
        text-align: left;
        width: 100%;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
    }

    ul {
        //padding-left: pxToRem(20);
        list-style-type: none;
    }

    ul li {
        position: relative;
        padding-left: pxToRem(30);
        margin-bottom: pxToRem(20);
        text-align: left;
        width: 100%;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
    }

    ul li:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: pxToRem(24);
        height: pxToRem(24);
        background-image: inline-svg('dot', $color-primary-800);
        background-repeat: no-repeat;
        background-size: pxToRem(24);
        background-position: 0 center;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin: pxToRem(8) 0;
    }

    table {
        text-align: left;
        width: 100%;
        margin: pxToRem(64) 0;
    }

    thead tr:last-child {
        border-bottom: 3px solid $color-secondary-300;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    th, td {
        padding: pxToRem(15) 0;
    }

    em, i {
        font-style: italic;
    }

}