.shop-modal {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, 0.3);
    backdrop-filter: blur(10px);
}

.shop-modal__frame {
    overflow: auto;
    width: 60%;
    max-height: 100%;
    padding: 30px;
    background: $color-white;
    box-shadow: 0 2px 20px rgba($color-black, 0.3);
}