.asp {
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);
    background-color: $color-soft-yellow;
    border-top: 1px solid $color-white;
}

.asp-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: pxToRem(28);

    &__image {
        padding-right: pxToRem(56);
    }

    .image__outer {
        //width: pxToRem(280);
        border-radius: 50%;
        overflow: hidden;

        img {
            aspect-ratio: 1 / 1;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
    }

    &__headline--topline {
        margin-bottom: pxToRem(6);
        @include getFigmaStyle('Desktop/SM/Regular/Default');
        text-transform: initial;
    }

    &__headline:not(.headline--topline) {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }

    &__content {
        margin-bottom: pxToRem(28);
    }

    .button {
        margin-top: pxToRem(48);
    }
}

.asp__body {
    &-left {
        padding-right: pxToRem(56);
    }

    &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: pxToRem(56);
        border-left: 1px solid $color-white;
    }
}

.asp__phone, .asp__email {
    color: $color-secondary-1300;
    @include getFigmaStyle('Desktop/H4/Bold/Default');

    & > span {
        display: inline-block;
        min-width: pxToRem(30);
    }
}

.asp__email {
    margin-top: pxToRem(6);
}

.faqteaser__outer:has(+ .asp) {
    margin-bottom: initial;
}

@include media-breakpoint-down(lg) {
    .asp-item {
        &__image {
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .asp-item {
        &__body {
            flex-direction: column;
            align-items: center;
        }

        &__content {
            text-align: center;
        }
    }

    .asp__body {
        &-left {
            padding-right: 0;
            text-align: center;
        }

        &-right {
            align-items: center;
            border-left: 0;
            padding-left: 0;
            margin-top: pxToRem(28);
        }
    }
}