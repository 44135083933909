.intermediate {
    position: relative;
    height: calc(50vw - 80px);
    overflow: hidden;

    &__wrapper {
        position: relative;
    }

    &__inner {
        overflow: hidden;
    }
}

@include media-breakpoint-down(md) {
    .intermediate {
        height: calc(133vw - 80px);
    }
}