.contentelement {
    & > .container > .grid {
        row-gap: pxToRem(128);
    }
}

.contentelement-item {
    &__image + .contentelement-item__body {
        padding-left: pxToRem(112);
        margin-top: pxToRem(16);
    }

    &__headline {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
        margin-bottom: pxToRem(32);
    }
}

@include media-breakpoint-down(lg) {
    .contentelement-item {
        &__image + .contentelement-item__body {
            padding-left: pxToRem(64);
        }
    }
}

@include media-breakpoint-down(md) {
    .contentelement {
        & > .container > .grid {
            row-gap: pxToRem(72);
        }
    }

    .contentelement-item {
        &.grid {
            row-gap: pxToRem(36);
        }

        &__image + .contentelement-item__body {
            padding-left: initial;
            margin-top: initial;
        }
    }
}