@import "@sx-core/src/scss/base/slider.base.scss";

.slider__navigation {
    width: 100%;
    height: 0;
}

.slider__prev,
.slider__next {
    position: absolute;
    top: calc(50% - 25px);
    display: block;
    width: 50px;
    height: 50px;
    background-color: rgba($color-black, 0.3);
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
}

.slider__prev {
    left: 0;
    background-image: inline-svg('angle-left', $color-white);
}

.slider__next {
    right: 0;
    background-image: inline-svg('angle-right', $color-white);
}

.teaser--slider .teaser__item {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;
}

/* Liebe Mitarbeitende mit A am anfang, wir haben nette Beispiele für euch*/
//@import "@sx-core/src/scss/element/slider-center.scss";
//@import "@sx-core/src/scss/element/slider-open-left.scss";
//@import "@sx-core/src/scss/element/slider-one-big-centered.scss";

.slider {
    .splide__track {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

.slider-item {
    display: block;
    height: 100%;
    color: $color-secondary-1300;
    @include getFigmaStyle('Shadow/SM');

    &__body {
        padding: pxToRem(28) pxToRem(20);
        background-color: $color-white;
    }

    &__headline {
        margin-bottom: pxToRem(16);
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }
}

.splide__arrows {
    transition: all .3s ease;
}

.splide__arrow {
    width: pxToRem(72);
    height: pxToRem(72);
    background-color: $color-black-alpha-700;
    background-position: center;
    background-size: 48px;
    background-repeat: no-repeat;
    transition: all .3s ease;

    &--prev {
        left: pxToRem(36);
        background-image: inline-svg('arrow-left-lg', $color-white);
    }

    &--next {
        right: pxToRem(36);
        background-image: inline-svg('arrow-right-lg', $color-white);
    }
}


