/*** || Preis ***/

.teaser-item, .teaser-item-small, .slider-item {
    position: relative;

    .price, &__price {
        position: absolute;
        top: pxToRem(16);
        right: pxToRem(16);
        z-index: 10;
        margin-top: initial;
        background-color: $color-primary-800;
        border-radius: 50%;

        .price__inner {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            column-gap: initial;
            width: pxToRem(90);
            height: pxToRem(90);
            padding-bottom: pxToRem(8);
            color: $color-white;
        }

        .price__prefix, .price__suffix {
            @include getFigmaStyle('Desktop/XS/Regular/Default');
        }

        .price__suffix {
            display: none;
        }

        .price__price {
            display: block;
            @include getFigmaStyle('Desktop/H5/Bold/Default');
            white-space: nowrap;
        }
    }
}

/*** || Teaser MD ***/

.teaser {
    position: relative;

    .grid {
        column-gap: pxToRem(56);
        row-gap: pxToRem(24);
    }

    &__item {
        position: relative;
    }
}

.teaser-item {
    display: block;

    &__body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    &__headline {
        padding: pxToRem(12) pxToRem(20);
        color: $color-secondary-1300;
        @include getFigmaStyle('Desktop/H6/Bold/Default');
        text-align: center;
        background-color: $color-white;
        border-radius: 320px;
        transition: all 0.3s ease;
    }

    &:hover, &:focus {
        .teaser-item__headline {
            transform: translateY(-6px);
        }
    }
}

@include media-breakpoint-down(xl) {
    .teaser {
        .grid {
            column-gap: pxToRem(24);
        }
    }
}

@include media-breakpoint-up(lg) {
    .teaser__item {
        position: relative;
        flex: 1;
        padding-bottom: 70vh;
        overflow: hidden;
        transition: all 1s ease;

        &:hover {
            flex: 1.8;
        }
    }

    .teaser-item {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: calc((70vh * 1) + 56px);
    }
}

/*** || Teaser SM ***/

.teaser-small {
    position: relative;
    padding-bottom: pxToRem(128);
    margin-bottom: initial;
    background-color: $color-soft-yellow;
    overflow: hidden;

    .splide__track {
        overflow: initial;
    }

    &__bg-image {
        position: relative;
    }

    .claim {
        padding-top: pxToRem(64);
        padding-bottom: pxToRem(64);
    }
}

.teaser-small-grid {
    align-items: flex-start;
}

.teaser-small__items {
    &.grid {
        margin-left: -28px;
        margin-right: -28px;
    }

    &.grid > [class^=col], &.grid > [class*=col] {
        padding-left: 28px;
        padding-right: 28px;
    }
}

.teaser-small__item {
    z-index: 10;
}

.teaser-item-small {
    display: block;
    height: 100%;
    color: $color-secondary-1300;
    background-color: $color-white;
    @include getFigmaStyle('Shadow/SM');
    transition: all .3s ease;

    &:hover, &:focus {
        @include getFigmaStyle('Shadow/LG');
    }

    &__image-link {
        &:hover, &:focus {
            & + .teaser-item-small {
                @include getFigmaStyle('Shadow/LG');
            }
        }
    }

    &__image {
        position: relative;
        margin-top: -75% //Format 4_3
    }

    .price {
        top: pxToRem(16);
        right: pxToRem(16);
    }

    &__body {
        padding: pxToRem(28) pxToRem(20) pxToRem(44);
    }

    &__headline:not(.headline--topline) {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    &__headline--topline {
        margin-top: pxToRem(8);
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
    }

    &__subline {
        margin-top: pxToRem(8);
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
    }

    &__teasertext {
        margin-top: pxToRem(16);
    }

    .facts {
        display: flex;
        flex-direction: column;
        row-gap: pxToRem(8);
        margin-top: pxToRem(32);
    }
}

.asp + .teaser-small {
    margin-top: initial;
}

@include media-breakpoint-down(lg) {
    .teaser-small {
        .claim {
            justify-content: initial;
        }
    }

    .teaser-small .container {
        padding-left: initial;
        padding-right: initial;
    }

    .teaser-small__items {
        &.grid {
            margin-left: 0;
            margin-right: 0;
        }

        &.grid > [class^=col], &.grid > [class*=col] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-small {
        padding-bottom: $modulspacing-mobile-m;

        .claim {
            justify-content: center;
        }
    }
}

/*** || Teaser USP ***/

.teaser-usp {
    position: relative;
    padding-top: pxToRem(96);
    padding-bottom: pxToRem(96);
    background-color: $color-soft-yellow;
    counter-reset: teaser-usp;

    .image__outer {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: calc(100vh + 106px);
        //padding-top: 106px;
        overflow: hidden;

        .image {
            //width: 40vw;
            //max-height: 70vh !important;
            overflow: hidden;
            //height: 100%;
            //max-height: calc(100vh - 106px - 48px - 48px);
            //width: auto;
            //margin-right: 100px;
            //margin-left: 100px;
            aspect-ratio: 1 / 1;
            //padding-bottom: 100%;
            opacity: 0;
            transition: all .3s ease;
        }
    }
}

.teaser-usp__item:first-child .teaser-item-usp {
    opacity: 1;

    .image {
        opacity: 1;
    }
}

.teaser-item-usp {
    position: static;
    opacity: 1;
    height: 100vh;
    transition: all .3s ease;

    &__headline {
        margin-bottom: pxToRem(48);
        @include getFigmaStyle('Desktop/H1/Bold/Default');
        counter-increment: teaser-usp;

        &::before {
            content: counter(teaser-usp, decimal-leading-zero) '';
            display: block;
            margin-bottom: pxToRem(16);
            color: $color-primary-800;
            @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
        }
    }

    &__content {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    &__body {
        opacity: 0;
    }

    &.active {
        opacity: 1;

        .teaser-item-usp__image {
            z-index: 10;
        }

        .image {
            opacity: 1;
            transition: all .3s ease;
        }

        .teaser-item-usp__body {
            //transform: translateX(-40px);
            opacity: 1;
        }
    }

    &__readmore {
        margin-top: pxToRem(48);
    }
}

.teaser-item-usp__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
}

.teaser-usp .teaser-item-usp {
    .teaser-item-usp__image {
        padding-left: 64px;
    }

    .teaser-item-usp__body {
        padding-right: 64px;
    }
}

.teaser-item-usp__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //width: 500px;
    //height: calc(100vh - #{$header-height});
    height: 100vh;
    //padding-top: pxToRem(106);
    //margin-left: 60vw;
    transition: all 1.7s ease;
}

.teaser-usp__item:first-child {
    .teaser-item-usp__body {
        height: 100%;
        padding-top: 106px;
    }
}

.teaser-usp__item:last-child {
    .teaser-item-usp {
        height: calc(100vh + 106px);
    }
}

@media(max-width: 1340px) {
    .teaser-usp {
        .image__outer {
            height: calc(100vh + 80px);
        }
    }

    .teaser-item-usp__body {
        height: calc(100vh - 80px);
    }

    .teaser-usp__item:last-child {
        .teaser-item-usp {
            height: 100vh;
        }
    }
}

@include media-breakpoint-down(xl) {
    .teaser-usp {
        padding-top: pxToRem(72);
        padding-bottom: pxToRem(72);

        & > .grid {
            row-gap: pxToRem(72);
        }
    }

    .teaser-item-usp {
        height: auto;

        &__image {
            position: relative;
            height: auto;
        }

        .image__outer {
            position: initial;
            height: auto;
            padding-top: initial;

            .image {
                max-height: initial;
                opacity: 1;
            }
        }

        &__body {
            height: auto;
            padding-top: initial;
            margin-top: pxToRem(36);
            opacity: 1;
        }

        &__headline {
            margin-bottom: pxToRem(24);
        }

        &__readmore {
            margin-top: pxToRem(28);
        }
    }

    .teaser-usp .teaser-item-usp {
        .teaser-item-usp__image {
            padding-left: 28px;
        }

        .teaser-item-usp__body {
            padding-right: 28px;
        }
    }

    .teaser-usp__item:first-child .teaser-item-usp__body {
        padding-top: initial;
    }

    .teaser-usp__item:not(:last-child) {
        margin-bottom: pxToRem(72);
    }

    .teaser-usp__item:last-child {
        .teaser-item-usp {
            height: auto;
        }
    }
}

@include media-breakpoint-down(lg) {
    .teaser-usp .teaser-item-usp {
        .teaser-item-usp__image {
            padding-left: 6px;
        }

        .teaser-item-usp__body {
            padding-right: 6px;
        }
    }
}

/*** || Facts ***/

.facts {
    .fact {
        position: relative;
        padding-left: pxToRem(30);
        @include getFigmaStyle('Desktop/MD/Bold/Default');

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            display: inline-block;
            width: pxToRem(18);
            height: pxToRem(18);
            background-color: $color-primary-800;
            background-image: inline-svg('arrow-sm-right', $color-white);
            background-position: center;
            background-size: pxToRem(8);
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }
}