.accordion {
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);
    background-color: $color-soft-yellow;

    .grid {
        row-gap: pxToRem(48);
    }
}

.accordion-item {
    background-color: $color-white;
    @include getFigmaStyle('Shadow/SM');

    &__header {
        cursor: pointer;
        position: relative;
        padding: pxToRem(32) pxToRem(64) pxToRem(32) pxToRem(28);
        @include getFigmaStyle('Desktop/H5/Bold/Default');

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 18px);
            right: pxToRem(16);
            display: block;
            width: 36px;
            height: 36px;
            background-color: $color-primary-800;
            background-image: inline-svg('plus-md', $color-white, transparent);
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
            border-radius: 50%;
            transition: all .3s ease;
        }
    }

    &__main {
        padding: pxToRem(0) pxToRem(28) pxToRem(36);
    }

    &__content {
        @include getFigmaStyle('Desktop/MD/Regular/Default');

        .content a {
            color: $color-white;
            text-decoration: underline;
        }
    }
}

.accordion__item.uk-open .accordion-item__header:after {
    background-image: inline-svg('minus-md', $color-white, transparent);
}

@include media-breakpoint-down(md) {
    .accordion {
        padding-top: pxToRem(24);
        padding-bottom: pxToRem(24);

        .grid {
            row-gap: pxToRem(24);
        }
    }
}

/*** Accordion-Image ***/

.accordion-image__outer {
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(16);
    background-color: $color-soft-yellow;
}

.item-accordion-image__storage {
    display: none;
}

.item-accordion-image__content {
    display: none;
}

.accordion-image__outer {
    .item-accordion-image__content-outer {
        padding: pxToRem(48);
        color: $color-white;
        background: $color-primary-800;

        a {
            color: $color-white;
            text-decoration: underline;
        }
    }
}

.accordion-image-item {
    margin-bottom: pxToRem(48);

}

.accordion-image__item {
    position: relative;
    @include getFigmaStyle('Shadow/SM');
    background-color: $color-white;
    transition: all .3s ease;

    //&:hover, &:focus, &.show-arrow {
    //    @include getFigmaStyle('ShadowLg');
    //}

    @include media-breakpoint-up(md) {
        &:hover, &:focus, &.show-arrow {
            box-shadow: 0 4px 36px 2px rgb(0 0 0 / 20%);
        }

        &:hover, &:focus {
            cursor: pointer;
        }
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -48px;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px;
        border-color: transparent transparent $color-primary-800;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .3s ease;
    }

    &.show-arrow::after {
        opacity: 1;
    }
}

.accordion-image__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: pxToRem(12) pxToRem(20);
    background-color: $color-white;
    border-radius: 320px;

    .accordion-image-content__title--headline {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }
}

.item-accordion-image__content_inner {
    color: $color-white;

    p {
        color: $color-white;
    }

    .content a {
        color: $color-white;
        text-decoration: underline;
    }
}

.accordion-image__price-outer {
    margin-top: pxToRem(32);

    .image-accordion__price {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }
}

.item-accordion-image__content {
    margin-bottom: pxToRem(48);
}

.accordionimage__addition {
    margin-top: pxToRem(2);
}

.accordionimage__notice {
    padding-top: pxToRem(16);
    margin-top: pxToRem(16);
    border-top: 1px solid $color-primary-1300;
}

@include media-breakpoint-down(md) {
    .accordion-image-item {
        margin-bottom: pxToRem(24);
    }

    .accordion-image__item::after {
        bottom: -24px;
    }

    .accordion-image__outer {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }
}




