.header__navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .menu-subline {
        transition: all .3s ease;
    }

    //@include media-breakpoint-up(xl) {
    @media(min-width: 1340px) {
        ul {
            display: flex;
            gap: pxToRem(48);
        }

        li.level-0 {
            position: relative;

            & > a {
                display: block;
                font-family: $font-family-secondary;
                color: $color-primary-800;
                font-size: pxToRem(26);
                transition: all .3s ease;

                &:hover {
                    //text-decoration: underline;
                    transform: translateY(-4px);
                    .menu-subline {
                        color: $color-primary-800;
                        transform: translateY(4px);
                    }
                }
            }

            &.active {
                & > a {
                    //transform: translateY(-4px);
                    .menu-subline {
                        color: $color-primary-800;
                        //transform: translateY(4px);
                    }
                }
            }

            &.has-children::after {
                content: "";
                position: absolute;
                bottom: -36px;
                left: calc(50% - 14px);
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 14px 12px 14px;
                border-color: transparent transparent $color-secondary-1300 transparent;
                transition: all .6s ease;
                opacity: 0;
            }

            &:hover {
                & > ul {
                    opacity: 1;
                    pointer-events: auto;
                }

                &.has-children::after {
                    opacity: 1;
                }
            }
        }

        .menu-subline {
            margin-top: pxToRem(2);
            font-family: $font-family-primary;
            font-size: pxToRem(16);
            color: $color-secondary-1000;
        }

        ul ul {
            display: block;
            position: absolute;
            z-index: 100;
            background: $color-secondary-1300;
            padding: pxToRem(28) pxToRem(20);
            top: 101px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            pointer-events: none;
            transition: all .6s ease;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
                display: block;
                width: 100%;
                height: 36px;
                transform: translateY(-100%);
            }

        }
        li.level-1 {
            min-width: pxToRem(250);
            padding: pxToRem(10) 0;

            //&:not(:first-child) {
            //    border-top: 1px solid $color-secondary-1200;
            //}

            a {
                position: relative;
                display: block;
                width: 100%;
                font-size: pxToRem(17);
                //padding-left: pxToRem(28);
                text-align: center;
                color: $color-white;
                transition: all 0.3s ease;

                //&::before {
                //    content: '';
                //    position: absolute;
                //    left: 0;
                //    top: 2px;
                //    display: inline-block;
                //    width: 16px;
                //    height: 16px;
                //    background-image: inline-svg('arrow-right-md', $color-primary-800, transparent);
                //    background-position: center;
                //    background-size: 16px;
                //    background-repeat: no-repeat;
                //    transition: all 0.3s ease;
                //}

                &:hover, &:focus {
                    color: $color-primary-800;
                    //padding-left: pxToRem(36);
                    //&::before {
                    //    background-image: inline-svg('arrow-right-md', $color-white, transparent);
                    //}
                }
            }
        }

        li.level-1.current {
            a {
                color: $color-primary-800;

                //padding-left: pxToRem(36);
                //&::before {
                //    background-image: inline-svg('arrow-right-md', $color-white, transparent);
                //}
            }

            & ~ .current {
                a {
                    color: $color-white;
                }
            }
        }

        .header__socials {
            display: none;
        }
    }
}

.header__logo {
    &--default {
        display: block;
    }

    //&--scrolled {
    //    display: none;
    //}
}

.header__logo {
    margin-top: auto;
}

.header__cta.button {
    display: none;
}

//@include media-breakpoint-down(xl) {
@media(max-width: 1340px) {
    .js-nav-open {
        overflow: hidden;

        .header__navigation {
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        }
    }

    .js-nav-scrolled {
        .header {
            height: 80px;
            padding: initial;
            box-shadow: 0 0 3px 0 rgba($color-black, 0.16);
        }

        .header__logo {
            &--default {
                display: none;
            }

            &--scrolled {
                display: block;
            }
        }

        .button.header__cta {
            display: inline-flex;
            margin-left: auto;
        }
    }

    .header__navigation {
        background: $color-secondary-1300;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 200;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        padding: pxToRem(80) pxToRem(36);
        opacity: 0;
        pointer-events: none;
        overflow: auto;
        transform: translateY(-100%);
        transition: all 0.6s ease;

        & > ul {
            width: 100%;
        }

        .header__navigation-topline {
            display: none;
        }

        .menu-subline {
            color: $color-primary-800;
            font-size: pxToRem(16);
            font-family: $font-family-primary;
            font-weight: 400;
        }

        li.level-0 {
            position: relative;
            //padding: pxToRem(10) pxToRem(20);
            padding: pxToRem(20) 0 pxToRem(8);
            width: 100%;

            a {
                position: relative;
                color: $color-white;
                display: block;
                font-family: $font-family-secondary;
                font-size: pxToRem(30);
                font-weight: 700;
            }

            & > ul {
                display: none;
                padding-top: pxToRem(16);
            }
        }

        li.level-1 {
            padding: pxToRem(4) 0;

            .menu-subline {
                display: none;
            }

            a {
                padding-left: pxToRem(38);
                font-family: $font-family-primary;
                font-size: pxToRem(16);
                font-weight: 400;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-image: inline-svg('arrow-right-md', $color-primary-800);
                    background-position: center;
                    background-size: 24px;
                    background-repeat: no-repeat;
                }
            }
        }

        .plus {
            position: absolute;
            right: 0;
            bottom: 0;
            display: block;
            width: 24px;
            height: 24px;
            z-index: 10;

            &:after {
                content: '';
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                background-image: inline-svg('plus-md', $color-white);
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        li.active > a {
            color: $color-primary-800;
        }

        .js-subnav-open .plus {
            &::after {
                background-image: inline-svg('minus-md', $color-white);
            }
        }
    }

    .header__logo {
        margin-top: initial;
    }
}

.burger {
    display: none;
    position: relative;
    z-index: 600;
    width: pxToRem(48);
    height: pxToRem(48);

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger {
        width: pxToRem(36);
        height: pxToRem(36);
        padding: pxToRem(8);
        background-color: $color-primary-800;
        border-radius: 50%;
    }

    .hamburger-box {
        width: 20px;
        height: 14px;
    }

    .hamburger-inner {
        width: 20px;
        height: 2px;
        background-color: $color-white;
        transition: background-color .3s ease;

        &::before, &::after {
            width: 20px;
            height: 2px;
            background-color: $color-white;
        }

        &::before {
            top: -6px;
        }

        &::after {
            bottom: -6px;
        }
    }
}

.js-nav-open .burger {
    .hamburger-inner {
        background-color: $color-white;

        &::before, &::after {
            background-color: $color-white;
        }
    }
}

.header__logo--nav {
    display: none;

    a {
        color: $color-white;
    }
}

.address--nav {
    display: none;
    margin-top: pxToRem(64);
    color: $color-white;

    .address__title {
        font-size: pxToRem(17);
        font-weight: 700;
    }

    .address__address {
        margin-top: pxToRem(8);
    }

    .address__phone {
        margin-top: pxToRem(8);
    }

    .address__item a {
        color: $color-white;
    }

    .address__item span {
        display: inline-block;
        min-width: 24px;
        font-weight: 700;
    }
}

.header__socials {
    column-gap: pxToRem(16);
    margin-top: pxToRem(40);

    img {
        width: 48px;
    }
}

.header__navigation-topline {
    display: flex;
    align-items: center;

    .header-cta:not(:first-child) {
        margin-left: pxToRem(8);
    }

    .header-cta--phone {
        margin-left: pxToRem(36);
        text-transform: uppercase;
    }
}

//@include media-breakpoint-down(xl) {
@media(max-width: 1340px) {
    .header__logo--nav, .address--nav, .button.header-cta--nav {
        display: block;
    }

    .burger {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
