.bookinggap {
    &.modulspacing {
        margin-top: pxToRem(64);
        margin-bottom: pxToRem(64);
    }

    &__headline {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    &__content {
        padding: pxToRem(36) 0;
        margin: pxToRem(36) 0;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;

        ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: pxToRem(16);
            row-gap: pxToRem(18);
        }

        li {
            display: flex;
            align-items: center;
            min-width: pxToRem(420);
            max-width: calc(33% - #{pxToRem(16)});
            flex: 1 0 calc(33% - #{pxToRem(8)});
            column-gap: pxToRem(8);
            @include getFigmaStyle('Desktop/SM/Regular/Default');

            &::before {
                content: '';
                display: inline-block;
                flex-shrink: 0;
                width: pxToRem(28);
                height: pxToRem(28);
                background-image: inline-svg('calendar-circle', $color-primary-800, transparent);
                background-size: pxToRem(24);
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__notice {
        display: flex;
        flex-wrap: wrap;
        column-gap: pxToRem(48);
        row-gap: pxToRem(16);
        margin-top: pxToRem(36);
    }
}

.view-frontpage {
    .bookinggap__item {
        padding: pxToRem(48) pxToRem(36);
        background-color: $color-soft-yellow;
    }
}

body:not(.view-frontpage) {
    .bookinggap {
        padding: pxToRem(48) 0;
        background-color: $color-soft-yellow;
        border-bottom: 1px solid $color-white;
    }
}

.bookinggap + .formular {
    margin-top: initial;
}

.bookinggap:has(+ .formular) {
    margin-bottom: initial;
}

@include media-breakpoint-down(sm) {
    .bookinggap {
        &__content {
            li {
                min-width: 100%;
                width: 100%;
                flex: auto;
            }
        }
    }
}