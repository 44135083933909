@import "@sx-core/src/scss/base/button.base.scss";

$btn-font-family: $font-family-secondary;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-font-weight: 400;

$btn-border-radius: 320px;
$btn-border-width: 2px;
$btn-border-style: solid;


$btn-border-width: 2px;
$btn-disabled-opacity: 1;
$btn-box-shadow: null;
$btn-focus-width: $btn-border-width;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $color-white;
$btn-active-box-shadow: null;


$scale-factor-sm: 0.75;
$scale-factor-lg: 1.25;


/*default*/

$btn-padding-x: pxToRem(34); //rechts
$btn-padding-y: pxToRem(10); //oben
$btn-font-size: pxToRem(18);
$btn-line-height: 1.5;
$btn-border-radius: $btn-border-radius;

/*md*/
$btn-padding-x-md: $btn-padding-x;
$btn-padding-y-md: $btn-padding-y;
$btn-font-size-md: $btn-font-size;
$btn-line-height-md: $btn-line-height;
$btn-border-radius-md: $btn-border-radius;

/*sm*/
$btn-padding-x-sm: pxToRem(14);
$btn-padding-y-sm: pxToRem(4);
$btn-font-size-sm: pxToRem(18);
$btn-line-height-sm: 1.3;
$btn-border-radius-sm: $btn-border-radius;

/*lg*/
$btn-padding-x-lg: pxToRem(46);
$btn-padding-y-lg: pxToRem(18);
$btn-font-size-lg: pxToRem(18);
$btn-line-height-lg: 1.5;
$btn-border-radius-lg: $btn-border-radius;


/*ICON*/

$btn-icon-size: 24px;


@mixin button-variant(
    $background-color,
    $border-color,


    $color: text-contrast($background-color),

    $hover-background-color: darken($background-color,30%),
    $hover-border-color: darken($background-color,30%),
    $hover-color: text-contrast($hover-background-color),

    $active-background-color: lighten($background-color,30%),
    $active-border-color: lighten($background-color,30%),
    $active-color: text-contrast($active-background-color),

    $disabled-background-color: $color-grey-500,
    $disabled-border-color: $color-grey-500,
    $disabled-color: text-contrast($color-white),

    $border-width: $btn-border-width,
    $border-style: $btn-border-style

) {


    color: $color;
    background-color: $background-color;
    border-color: $border-color;
    border-width: $border-width;
    border-style: $border-style;
    //box-shadow: $shadow;

    &:hover {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
    }

    &:focus {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
        outline-color: $hover-border-color;
    }

    &:active {
        color: $active-color;
        background-color: $active-background-color;
        border-color: $active-border-color;
        outline-color: $active-border-color;
    }

    &:disabled,
    &.disabled {
        color: $disabled-color;
        background-color: $disabled-background-color;
        border-color: $disabled-border-color;
    }
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius, $line-height: null) {
    @debug ($padding-y);
    @debug $padding-y - pxToRem(1px);
    //--#{$boosted-variable-prefix}icon-spacing: #{$icon-spacing}; : used for icons
    //padding: ($padding-y - pxToRem(1px)) $padding-x ($padding-y + pxToRem(1px));
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    border-radius: $border-radius

    //TODO Icon Size & Paddings & Margins
}


@mixin button-icon(
    $icon: 'arrow-right',
    $pseudo: after,

    $width: 24px,
    $height: $width,

    $icon-width: 24px,
    $icon-height: $icon-width,

    $margin-left: 50px,
    $margin-right: 6px,

    $border-radius: 50%,
    $border-color: $color-black,
    $border-width: $btn-border-width,

    $icon-bg-color: $color-primary-800,
    $color: $color-black,
    $fillcolor: transparent,
	$strokecolor: $color,

) {

    position: relative;
    display: flex;
    align-items: center;

    @if $pseudo == before {
        padding-left: $margin-left;
    }

    @if $pseudo == after {
        padding-right: $margin-right;
    }

    &:#{$pseudo} {
        content: '';
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: $border-radius;
        width: $width;
        height: $height;

        background-color: $icon-bg-color;
        background: inline-svg($icon, $color, $fillcolor, $strokecolor);
        background-size: $icon-width $icon-height;
        background-repeat: no-repeat;
        background-position: center center;

        @if $pseudo == before {
            margin-right: $margin-right;
        }

        @if $pseudo == after {
            margin-left: $margin-left;
        }
    }


    &:hover {
        //TODO
    }

    &:focus {
        //TODO
    }

    &:active {
        //TODO
    }

    &:disabled,
    &.disabled {
        //TODO
    }


}


a.button, .button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: $btn-font-family;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}


/* Button sizes*/

.button--sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm);
}

.button--md {
    @include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $btn-border-radius-md, $btn-line-height-md);
}

.button--lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg);
}


/*Buttons bauen */

.button--primary {
    @include button-variant($color-primary-800, $color-primary-700, $color-white,
    $hover-background-color: $color-primary-1000,
    $hover-border-color: $color-primary-700,
    $hover-color: $color-white,

    $active-background-color: $color-primary-1000,
    $active-border-color: $color-primary-700,
    $active-color: $color-white,
    );
}

.button--primary.button--invert {
    @include button-variant($color-white, $color-primary-700, $color-secondary-1300,
    $hover-background-color: $color-white,
    $hover-border-color: $color-primary-700,
    $hover-color: $color-primary-800,

    $active-background-color: $color-white,
    $active-border-color: $color-primary-700,
    $active-color: $color-primary-800,
    );
}

.button--icon.button--sm.button--primary {
    padding: initial;
    padding-top: pxToRem(5);
    padding-bottom: pxToRem(5);
    padding-left: pxToRem(48);
    @include getFigmaStyle('Desktop/MD/Medium/Underline');
    color: $color-secondary-1300;
    background-color: initial;
    border: none;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 36px;
        height: 36px;
        background-color: $color-primary-800;
        background-image: inline-svg('arrow-right-md', $color-white);
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all 0.3s ease;
    }

    &:hover, &:focus {
        &::before {
            background-color: $color-primary-1000;
        }
    }
}

/* Outline example */
.button--secondary {
    @include button-variant(transparent, $color-primary-800, $color-secondary-1300,
    $hover-background-color: $color-primary-800,
    $hover-border-color: $color-primary-800,
    $hover-color: $color-white,

    $active-background-color: $color-primary-800,
    $active-border-color: $color-primary-800,
    $active-color: $color-white,

    $border-width: 1px,
    );
}

.button--secondary.button--invert {
    @include button-variant(transparent, $color-white, $color-white,
    $hover-background-color: $color-white,
    $hover-border-color: $color-white,
    $hover-color: $color-secondary-1300,

    $active-background-color: $color-white,
    $active-border-color: $color-white,
    $active-color: $color-secondary-1300,

    $border-width: 1px,
    );
}

.button--icon.button--sm.button--secondary {
    padding: initial;
    //padding-top: pxToRem(5);
    //padding-bottom: pxToRem(5);
    padding-left: pxToRem(36);
    @include getFigmaStyle('Desktop/MD/Medium/Underline');
    color: $color-secondary-1300;
    background-color: initial;
    border: none;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: pxToRem(28);
        height: pxToRem(28);
        background-color: transparent;
        background-image: inline-svg('arrow-right-md', $color-black);
        background-size: pxToRem(16);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all 0.3s ease;
        border: 1px solid $color-primary-800;
    }

    &:hover, &:focus {
        &::before {
            background-color: $color-primary-1000;
            background-image: inline-svg('arrow-right-md', $color-white);
        }
    }
}

.button--default, .button {

}

.button--icon {
    //@include button-icon();
}

.button--default.button--icon, .button.button--icon {

}

.button--link {

}

.button--link.button--icon {

}