
.layout-maintenance {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $color-background;
}

.maintenance {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 60px 30px;
    text-align: center;
}

.maintenance__icon {
    width: 30%;
    margin: 0 auto 20px;
}

.maintenance__icon:before {
    content: '\00a0';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    background-size: contain;
    background-image: inline-svg('construction', $color-warning);
}

.maintenance__header h1 {
    font-size: 32px;
    color: $color-warning;
}

.maintenance__content p {
    font-size: 16px;
    color: $color-grey-800;
}
