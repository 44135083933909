.stoerer {
    position: absolute;
    top: pxToRem(40);
    right: pxToRem(104);
    z-index: 100;

    &-inner {
        width: pxToRem(180);
        height: pxToRem(180);
        padding: pxToRem(36) pxToRem(18);
        color: $color-white;
        text-align: center;
        background-color: $color-primary-800;
        border: 2px solid $color-primary-700;
        border-radius: 50%;
    }

    &__headline {
        font-family: $font-family-secondary;
        font-size: pxToRem(23);
        font-weight: 400;
    }

    &__content {
        color: $color-primary-200;
    }
}

@include media-breakpoint-down(lg) {
    .stoerer {
        right: pxToRem(64);
    }
}

@include media-breakpoint-down(sm) {
    .stoerer {
        top: pxToRem(12);
        right: pxToRem(36);

        &-inner {
            padding: pxToRem(28) pxToRem(12);
            width: pxToRem(160);
            height: pxToRem(160);
        }

        &__headline {
            font-size: pxToRem(20);
        }

        &__content {
            font-size: pxToRem(16);
        }
    }
}

/*
.stoerer {
    position: absolute;
    top: pxToRem(40);
    right: pxToRem(104);
}

@include media-breakpoint-down(lg) {
    .stoerer {
        right: pxToRem(64);
    }
}
 */