.gallery {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: pxToRem(48);
        grid-row-gap: pxToRem(48);
    }

    &__item--image {
        position: relative;
        transition: all .3s ease;

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);

                &::before, &::after {
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .gallery {
        &__grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: pxToRem(24);
        }
    }
}